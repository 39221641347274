import { ROUTES } from "~/helpers/routes/routes";
import dayjs from "dayjs";

export default defineNuxtRouteMiddleware(async (to) => {
  const expiredToken = (to.query.expire as string)?.replace(" ", "+");
  const expireDate = dayjs(expiredToken);
  const isExpired = dayjs().isAfter(expireDate);

  if (to.query.expire && to.path === ROUTES.RESET_PASSWORD && isExpired) {
    return navigateTo(ROUTES.FORGOTTEN_PASSWORD + "?q=passwordResetExpired");
  }

  if ((to.path === ROUTES.RESET_PASSWORD && !to.query.key) || (to.path === ROUTES.RESET_PASSWORD && !to.query.expire)) {
    return navigateTo(ROUTES.HOMEPAGE);
  }
});
